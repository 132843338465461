@tailwind base;
@tailwind components;
@tailwind utilities;

/* || Global Variables */

:root {
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;
}

@layer base {
  /* || Fonts */

  @font-face {
    font-family: 'Gill Sans Nova';
    src: url('../../../public/fonts/GS_Nova_SemiBold.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    src: url('../../../public/fonts/Poppins_Regular.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: url('../../../public/fonts/Poppins_SemiBold.ttf');
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  /* Remove background color for chrome auto-complete  */
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  input[data-autocompleted] {
    background-color: transparent !important;
  }
  /* --end */

  .box {
    @apply mx-auto w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl;
  }

  .panel {
    @apply rounded-xl bg-tileDark p-4;
  }

  .floating-input > input::placeholder,
  .floating-input > textarea::placeholder {
    @apply text-transparent;
  }

  .floating-input > input:focus,
  .floating-input > input:not(:placeholder-shown),
  .floating-input > textarea:focus,
  .floating-input > textarea:not(:placeholder-shown),
  .floating-input > select:focus,
  .floating-input > select:not(:invalid) {
    @apply pt-8;
  }

  .floating-input > input:focus ~ label,
  .floating-input > input:not(:placeholder-shown) ~ label,
  .floating-input > select:focus ~ label,
  .floating-input > select:not(:invalid) ~ label {
    @apply -translate-y-3 translate-x-0.5 scale-[0.85] opacity-75;
  }

  .floating-input > textarea:focus ~ label,
  .floating-input > textarea:not(:placeholder-shown) ~ label {
    @apply -translate-y-4 translate-x-0.5 scale-[0.85] opacity-75;
  }

  [type='checkbox'] {
    width: 1rem;
    height: 1rem;
    color: #4dc2c6;
    vertical-align: middle;
    -webkit-appearance: none;
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    border-radius: 25%;
    background-color: #2f2e31;
    transition: background 300ms;
    cursor: pointer;
  }

  /* Pseudo element for check styling */

  [type='checkbox']::before {
    content: '';
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #2f2e31;
  }

  [type='checkbox']:checked {
    background-color: currentcolor;
  }

  [type='checkbox']:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  }

  [type='checkbox']:disabled {
    background-color: #2f2e31;
    opacity: 0.75;
    cursor: not-allowed;
  }

  [type='checkbox']::-ms-check {
    content: '';
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #2f2e31;
  }

  [type='checkbox']:checked::-ms-check {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  }

  /* Button Checkbox */
  .button-checkbox-control {
    @apply inline-block;
  }
  .button-checkbox-label {
    @apply block w-full cursor-pointer select-none rounded-full border border-white border-opacity-[0.12] bg-transparent bg-white bg-opacity-0 px-2.5 py-1 text-center text-xs font-medium text-white transition-colors ease-linear hover:bg-opacity-10 focus:border md:px-3 md:text-sm;
  }
  .button-checkbox {
    @apply absolute appearance-none opacity-0 disabled:cursor-not-allowed disabled:appearance-none disabled:opacity-0;
  }
  .button-checkbox:disabled + label {
    @apply cursor-not-allowed;
  }
  .button-checkbox:focus + label {
    @apply outline-none ring-1 ring-gray-300;
  }
  .button-checkbox:active + label {
    @apply bg-opacity-20;
  }
  .button-checkbox:checked + label {
    @apply bg-opacity-40;
  }

  /* || Buttons */
  .btn {
    @apply inline-block cursor-pointer select-none whitespace-nowrap rounded border border-transparent bg-[#212325] px-2.5 py-1.5 text-center align-middle font-dm-sans text-sm font-semibold text-white transition-colors duration-200 ease-in-out hover:bg-[#303336] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cloud disabled:cursor-default disabled:opacity-60 disabled:hover:bg-[#212325] sm:py-2.5 sm:px-3 sm:text-base sm:leading-[18/16rem];
  }

  .btn-outline {
    @apply border-white border-opacity-[0.12] bg-transparent focus:ring-0 focus:ring-offset-gray-400 disabled:hover:bg-transparent;
  }

  .btn-link {
    @apply bg-transparent hover:bg-transparent hover:underline focus:ring-0 focus:ring-offset-1 disabled:hover:bg-transparent;
  }

  .btn-block {
    @apply block w-full;
  }

  .btn-white {
    @apply border-gray-300 bg-cloud text-dark hover:bg-gray-300 focus:ring-gray-300 disabled:hover:bg-cloud disabled:hover:text-dark;
  }

  .btn-primary {
    @apply border-brand-primary bg-brand-primary hover:border-brand-primaryDark hover:bg-brand-primaryDark  focus:ring-brand-primaryDark disabled:hover:bg-brand-primary;
  }

  .btn-accent {
    @apply border-brand-accent bg-brand-accent text-[#182222] hover:border-brand-accentDark hover:bg-brand-accentDark focus:ring-brand-accentDark disabled:hover:bg-brand-accent;
  }

  .btn-dropdown {
    @apply relative flex items-center rounded-full bg-transparent p-1 text-darkText transition-colors duration-200 ease-out hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cloud;
  }

  /* || Modal */
  .overlay {
    @apply fixed z-modal animate-fadeInDown overflow-y-auto overflow-x-hidden rounded-3xl bg-tileDark p-10 text-cloud shadow-lg;
    top: 10vh;
    left: 5%;
    width: 90%;
    max-height: 75vh;
    z-index: 100;
  }

  .overlay-create {
    @apply fixed z-modal animate-fadeInDown overflow-y-auto overflow-x-hidden rounded-3xl bg-tileDark p-10 text-cloud shadow-lg;
    top: 10vh;
    left: 5%;
    width: 90%;
    max-height: 75vh;
    z-index: 100;
  }

  @media (min-width: 768px) {
    .overlay {
      max-width: 30rem;
      left: calc(50% - 15rem);
    }
    .overlay-create {
      max-width: 38rem;
      left: calc(50% - 19rem);
    }
  }

  .modal-reschedule-appointment {
    max-width: 400px;
  }

  .autocomplete-dropdown-container {
    position: absolute;
    z-index: 10;
    color: black;
  }

  /* || Time Slots */

  .timeslots {
    @apply mt-4 grid grid-cols-3 gap-4;
  }

  .timeslot {
    @apply w-full rounded-md bg-dark py-3 px-4 shadow transition-colors;
  }

  .timeslot span {
    @apply block overflow-hidden text-ellipsis whitespace-nowrap text-center text-sm text-cloud sm:text-base;
  }

  /* || Text Styles */

  .p-overflow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @apply max-w-full overflow-hidden;
  }
  .p-overflow-1 {
    -webkit-line-clamp: 1;
    @apply p-overflow;
  }
  .p-overflow-2 {
    -webkit-line-clamp: 2;
    @apply p-overflow;
  }
  .p-overflow-3 {
    -webkit-line-clamp: 3;
    @apply p-overflow;
  }

  /* || Filter Menu */

  .filter-menu {
    @apply overflow-hidden truncate rounded-md border border-white border-opacity-[0.12] bg-transparent text-sm text-white focus:border-gray-500 focus:shadow-sm focus:outline-none;
  }

  /* || SP Dashboard */

  .main-overview {
    grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  }

  /* || Dropzone */

  .drop-container {
    @apply flex flex-col font-dm-sans font-medium text-darkText antialiased;
  }

  .drop-area {
    @apply flex flex-1  cursor-pointer flex-col items-center rounded-md border-2 border-dashed border-white border-opacity-[0.12] bg-transparent p-5 outline-none transition-[border] duration-200 ease-in-out hover:border-opacity-25 active:border-opacity-40;
  }

  .drop-files {
    @apply block;
  }
}

@layer utility {
  .scrollbar::-webkit-scrollbar {
    @apply h-2 w-2;
  }
  .scrollbar::-webkit-scrollbar-track {
    @apply rounded-3xl bg-slate-700;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    @apply rounded-3xl bg-slate-500;
  }
}
